/* style scrollbar */

::-webkit-scrollbar {
  width: 5px;
}   
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  border-radius: 50px;
}
/* add border radius to scrollbar */
::-webkit-scrollbar-thumb {
  border-radius: 50px;
}

/* remove scrollbar */
/* ::-webkit-scrollbar {
  display: none;
} */