table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  /* height: 100%; */
  /* grid-area: a; */
  overflow-x: auto;
}

th {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #8e919c;
}

td {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

th,
td {
  text-align: left;
  padding: 5px;
  border-bottom: 1px solid #dfe8fc;
  font-size: 12px;
}

.table-image {
  height: 40px;
  width: 40px;
  margin-right: 10px;
  border-radius: 60px;
}
.pagination-container {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */
}
.pagination-bar {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.link {
  color: #2254d3;
  cursor: pointer;
}

.three-dots {
  font-size: 25px;
  font-weight: 900;
  color: #2254d3;
  cursor: pointer;
  position: relative;
}

/* Long text in table */
.foot-note-cell {
  position: relative;
  cursor: pointer;
}

.short-text {
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.full-text {
  display: none;
  position: absolute;
  right: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 1;
  white-space: normal;
  max-height: 500px;
  overflow-y: scroll;
  scrollbar-width: 20px;
  scrollbar-color: #2254d3 #eee;
  /* width: auto; */
  width: 500px; /* adjust the max-width as needed */
}

.foot-note-cell:hover .full-text {
  display: block;
}
