.rotate {
  background: transparent;
  position: absolute;
  transition: 0.2s;
  transform: rotate(180deg);
  cursor: pointer;
  border: none;
}

.return {
  background: transparent;
  position: absolute;
  transition: 0.2s;
  transform: rotate(0deg);
  cursor: pointer;
  border: none;
}
